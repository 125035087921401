<template>
  <div v-if="mCart">
    <div class="card has-margin-bottom">
      <div class="card-content">
        <div class="media">
          <div class="media-content">
            <h1>Step {{ mCart.LastStep }}</h1>
            <p class="title is-4">
              <span>
                {{
                  mCart.StartDate | parseIsoDateStringToDate | dateFormat
                }}</span
              >
              <span class="has-margin-left"
                >{{ mCart.StartMinutes | minutesToTime }} -
                {{ mCart.EndMinutes | minutesToTime }}</span
              >
              <span class="has-margin-left" v-if="mCart.CartType === 0"
                >Regular reservation</span
              >
              <span class="has-margin-left" v-if="mCart.CartType === 1"
                >Double booking</span
              >
              <span class="has-margin-left" v-if="mCart.CartType === 2"
                >Proposal</span
              >
            </p>
            <p class="subTitle is-4">{{ mCart.LocationName }}</p>
            <p class="subtitle is-6">
              <span>Expires in {{ msToTime(expiresOn) }}</span>
              <span class="has-margin-left is-size-7">
                <a @click="extendCart">
                  <span class="icon">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </span>
                  <span>Extend time</span>
                </a>
              </span>
            </p>
          </div>
        </div>

        <div class="content">
          <div v-if="mCart.IsBackend">
            <span>{{ mCart.ProfileName }}</span>
            <span v-if="mCart.CompanyId > 0"> - {{ mCart.CompanyName }}</span>
          </div>

          <ul class="is-size-7">
            <li v-for="space in mCart.Spaces" :key="space.Id">
              {{ space.SpaceName }} -
              <span class="has-text-weight-bold"
                >{{ space.Seats }} seats in {{ space.SettingName }}</span
              >
            </li>
          </ul>

          <ul class="is-size-7">
            <li v-for="option in mCart.Options" :key="option.Id">
              {{ option.Amount }}x {{ option.OptionName }}
            </li>
          </ul>

          <div class="has-text-weight-bold">
            {{ mCart.TotalExclTax | toCurrency }}
          </div>

          <div v-if="mCart.IsBackend" class="has-text-right">
            <a
              @click.left.exact="cartSummary(mCart)"
              @click.ctrl.left.exact="cartDetail(mCart.Id)"
              class="button is-primary"
              >Open</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cartProvider from '@/providers/cart'
import { EventBus } from '@/eventbus/event-bus'

export default {
  props: {
    cart: {
      default: null,
      type: Object,
    },
  },

  data() {
    return {
      currentDateTime: new Date().getTime(),
      expireDateTime: 0,
      expiresOn: 0,
      mCart: this.cart,
      timer: null,
    }
  },

  created() {
    let dt = new Date()
    this.expireDateTime =
      this.mCart.CreatedOn + 60000 * this.mCart.MinutesToExpire
    this.expiresOn = this.expireDateTime - this.currentDateTime
    this.activateCountDown()
  },

  methods: {
    cartSummary(cart) {
      this.$emit('cartSummary', cart)
    },

    cartDetail(cart) {
      this.$emit('cartDetail', cart)
    },

    activateCountDown() {
      this.timer = window.setInterval(this.countdown, 1000)
    },

    countdown() {
      let expiresOn = this.expiresOn
      this.expiresOn = expiresOn - 1000

      if (this.expiresOn <= 0) {
        this.reset()
      }
    },

    msToTime(s) {
      let ms = s % 1000
      s = (s - ms) / 1000
      let secs = s % 60
      s = (s - secs) / 60
      let mins = s % 60
      let hrs = (s - mins) / 60

      return (
        ('0' + hrs).slice(-2) +
        ':' +
        ('0' + mins).slice(-2) +
        ':' +
        ('0' + secs).slice(-2)
      )
    },

    reset() {
      window.clearInterval(this.timer)

      this.$emit('cartDeleted', this.mCart)
      cartProvider.methods.cancelCart(this.mCart.CartKey)
    },

    extendCart() {
      cartProvider.methods
        .extendCartTimeout(this.mCart.CartKey, 30)
        .then((response) => {
          if (response.status === 200) {
            this.expiresOn = this.expiresOn + 30 * 60000
            window.clearInterval(this.timer)
            this.activateCountDown()
            this.mCart = response.data
            this.$emit('cartExtended', this.mCart)

            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Cart timeout is extended!',
            })
          }
        })
    },
  },
}
</script>
